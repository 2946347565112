'use client';

import { signIn } from 'next-auth/react';

import { AkvaButton } from '@akva/components';
import { ArrowIcon } from '@akva/icons';
import { colors } from '@akva/profile';

export default function LoginButton() {
  return (
    <AkvaButton
      variant="text-button"
      hasIcon={true}
      iconPosition="right"
      onClick={() => signIn('akvaauth', { callbackUrl: '/' })}
    >
      Log in
      <ArrowIcon direction="right" color={colors.neutral1} />
    </AkvaButton>
  );
}
